import { useEffect } from "react";
import M from "@materializecss/materialize";
import Resizer from "react-image-file-resizer";

import { downloadExcel, sortByStringField } from "./Functions";
import { useSelector } from "react-redux";
import { setFieldOnFormByKey, setModalByIdByKey } from "../../services/redux/Project/ProjectSlice";
import { collectionsRS } from "../../services/redux/Project/selectors";
import { setDirectoryOfUser, subsCardsCollection } from "../../services/firebase/DbUtils";
import { selectedProjectRS, userProfileRS } from "../../services/redux/UserStateSlice";

export function useCollectionListenerForCard(dispatch) {
  const collections = useSelector(collectionsRS);
  const userProfile = useSelector(userProfileRS);
  const selectedProject = useSelector(selectedProjectRS);

  useEffect(() => {
    if (collections.length) {
      return subsCardsCollection(dispatch, collections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections]);

  useEffect(() => {
    setDirectoryOfUser(dispatch, selectedProject, userProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);
}

export function handleDownload(headers, report, filename, filteredData, paginatedData, selectedProject) {
  let csvData = [
    headers,
    ...Object.values(filteredData).map((elem) => {
      return headers.map((key) => {
        return getNestedCsvField(key, elem);
      });
    }),
  ];
  csvData = csvData.map((row) => row.join(",")).join("\n");
  switch (report) {
    case "inspections":
      downloadExcel(paginatedData, selectedProject, filename);
      break;
    default:
      downloadCsvData(csvData, filename);
      break;
  }
}

export async function setSelectOptions(dispatch, fetchData, selectField, fieldOnForm, fieldId) {
  let result = await fetchData();
  for (let row in fieldOnForm) {
    if (fieldOnForm[row][selectField]) {
      dispatch(setFieldOnFormByKey({ fields: [fieldId, row, selectField, "options"], data: result }));
      break;
    }
  }
}

export function handleFormOnChange(dispatch, e, id) {
  const { name, value } = e.currentTarget;
  dispatch(setModalByIdByKey({ id, key: name, data: value }));
}

export function closeModalById(id) {
  // e.preventDefault();
  // e.stopPropagation();
  const elem = document.getElementById(id);
  const instance = M.Modal.getInstance(elem);
  instance.close();
}

export function getFilteredData({ project }) {
  let {
    data,
    modal: { modalFilterFillData },
    fieldFilter,
  } = project;
  fieldFilter = Object.assign({}, ...Object.values(fieldFilter));
  data = Object.keys(data).map((e) => data[e]);
  const filtered = data.filter((elem) => {
    return Object.keys(modalFilterFillData).every((key) => {
      switch (key) {
        case "from":
          const to = new Date(elem[fieldFilter["to"]["rangeOf"]]) <= new Date(modalFilterFillData["to"]);
          const from = new Date(elem[fieldFilter["from"]["rangeOf"]]) >= new Date(modalFilterFillData["from"]);
          return to && from;
        case "to":
          return true;
        default:
          if (fieldFilter[key] === undefined) return true;
          if (fieldFilter[key]["formType"] === "chips") return deepCompareArraysOfObjects(elem[key], modalFilterFillData[key]);
          if (!elem[key]) return true;
          if (fieldFilter[key]["formType"] === "select") return elem[key] === modalFilterFillData[key];
          if (fieldFilter[key]["formType"] === "autocomplete") return deepCompareArraysOfObjects([elem[key]], [modalFilterFillData[key]]);
          if ( typeof elem[key] === 'number') return elem[key].toString().includes(modalFilterFillData[key]);
          return elem[key].toLowerCase().includes(modalFilterFillData[key].toLowerCase());
      }
    });
  });
  let filtered_ = {};
  filtered.forEach((doc) => (filtered_[doc.id] = doc));
  return filtered_;
}

export function updatePagination(state, currentPage) {
  let sortedFilteredData = sortByStringField(state.filteredData, state.initialState.sortField);
  sortedFilteredData = sortedFilteredData.map((e) => [e.id, e]);
  return {
    ...state,
    pagination: {
      ...state.pagination,
      numberOfPages: Math.ceil(Object.keys(state.filteredData).length / state.pagination.itemsperPage),
      currentPage,
    },
    paginatedData: Object.fromEntries(
      sortedFilteredData.slice(state.pagination.itemsperPage * (currentPage - 1), state.pagination.itemsperPage * currentPage)
    ),
  };
}

export function getNoOfPaginationPages(state) {
  const data = getFilteredData(state);
  const noOfData = Object.keys(data).length;
  const result = Math.ceil(noOfData / state.project.pagination.itemsperPage);
  return result;
}

export function getPaginatedData(state) {
  const filteredData = getFilteredData(state);
  const {
    sortField,
    pagination: { itemsperPage, currentPage },
  } = state.project;
  let sortedFilteredData = sortByStringField(filteredData, sortField);
  sortedFilteredData = sortedFilteredData.map((e) => [e.id, e]);
  const paginatedData = Object.fromEntries(sortedFilteredData.slice(itemsperPage * (currentPage - 1), itemsperPage * currentPage));
  return paginatedData;
}

export function handleFileContainer(dispatch, file, id) {
  dispatch(setModalByIdByKey({ id, key: "fileContainer", data: file }));
}

export function resizeFile(file) {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      640,
      640,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });
}

export function deepCompareArraysOfObjects(a, b) {
  if (!b.length) return true;
  try {
    return a.some((ea) => {
      return b.find((eb) => ea.value === eb.value) !== undefined;
    });
  } catch (e) {
    return false;
  }
}

function getNestedCsvField(key, elem) {
  switch (key) {
    case "company":
      return elem[key] ? elem[key].label : "";
    case "members":
    case "fromRolechip":
    case "toRolechip":
    case "ccRolechip":
      return elem[key] ? elem[key].map((e) => e.label).join(" - ") : "";
    default:
      return elem[key] ? elem[key] : "";
  }
}

function downloadCsvData(data, filename) {
  const element = document.createElement("a");
  const file = new Blob([data], { type: "text/csv" });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
}

// class CardsScreen extends Component {
//   constructor(props) {
//     super(props);
//     const { currentUser } = props;
//     this.state = {
//       currentUser,
//       data: {},
//       filteredData: {},
//       paginatedData: {},
//       fieldOnForm: {},
//       fieldFilter: {},
//       modalEditFillData: {},
//       modalFilterFillData: {},
//       modalAddFillData: {},
//       collection: "",
//       fileContainer: {},
//       pagination: {
//         currentPage: 1,
//         itemsperPage: 8,
//         numberOfPages: 1,
//       },
//     };
//     this.unsubscribe = function () {};
//   }

//   componentDidMount() {
//     this.collectionSnapshot(this.state.collection);
//   }

//   componentWillUnmount() {
//     this.unsubscribe();
//   }

//   collectionSnapshot(path) {
//     const q = query(collection(fbdb, path));
//     this.unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
//       const data = {};
//       snapshot.docs.forEach((doc) => (data[doc.id] = formatDocData(doc)));
//       // console.log(sortByStringField(data, "dateCreated"));
//       this.setState({
//         data,
//         // data: data.sort((a,b) => (a.dateCreated > b.dateCreated) ? 1 : ((b.dateCreated > a.dateCreated) ? -1 : 0)),
//         filteredData: data,
//       });
//       this.setState((prevState) => this.updatePagination(prevState, prevState.pagination.currentPage));
//     });
//   }

//   handleEdit = (docId) => {
//     this.setState((prevState) => ({
//       ...prevState,
//       modalEditFillData: prevState.data[docId],
//     }));
//   };

//   handleFileContainer = (file) => {
//     this.setState((prevState) => ({
//       ...prevState,
//       fileContainer: file,
//     }));
//   };

//   handleFilter = (e, modalId) => {
//     e.preventDefault();
//     let { data, modalFilterFillData, fieldFilter } = this.state;
//     fieldFilter = Object.assign({}, ...Object.values(fieldFilter));
//     data = Object.keys(data).map((e) => data[e]);
//     const filtered = data.filter((elem) => {
//       return Object.keys(modalFilterFillData).every((key) => {
//         switch (key) {
//           case "from":
//             const to = new Date(elem[fieldFilter["to"]["rangeOf"]]) <= new Date(modalFilterFillData["to"]);
//             const from = new Date(elem[fieldFilter["from"]["rangeOf"]]) >= new Date(modalFilterFillData["from"]);
//             return to && from;
//           case "to":
//             return true;
//           default:
//             if (fieldFilter[key]["formType"] === "chips") return this.deepCompareArraysOfObjects(elem[key], modalFilterFillData[key]);
//             if (!elem[key]) return true;
//             if (fieldFilter[key]["formType"] === "select") return elem[key] === modalFilterFillData[key];
//             return elem[key].toLowerCase().includes(modalFilterFillData[key].toLowerCase());
//         }
//       });
//     });
//     let filtered_ = {};
//     filtered.forEach((doc) => (filtered_[doc.id] = doc));
//     let newState = this.state;
//     newState.filteredData = filtered_;
//     newState = { ...newState, ...this.updatePagination(newState, newState.pagination.currentPage) };
//     this.setState(newState);
//     this.closeModalById(e, modalId);
//   };

//   deepCompareArraysOfObjects(a, b) {
//     if (!b.length) return true;
//     try {
//       return a.some((ea) => {
//         return b.find((eb) => ea.value === eb.value) !== undefined;
//       });
//     } catch (e) {
//       return false;
//     }
//   }

//   handleReply = (e) => {
//     e.preventDefault();
//     console.log("reply");
//   };

//   handleReset = (e, stateKey) => {
//     let newState = this.state;
//     newState[stateKey] = {
//       from: new Date(2023, 0, 2).toISOString().substring(0, 10),
//       to: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substring(0, 10),
//     };
//     newState.filteredData = newState.data;
//     newState = { ...newState, ...this.updatePagination(newState, newState.pagination.currentPage) };
//     this.setState(newState);
//     this.closeModalById(e, e.target.name);
//   };

//   handleUpdateDoc = async (e, modalId) => {
//     e.preventDefault();
//     if (e.nativeEvent.type === "submit") {
//       let { id, ...rest } = this.state.modalEditFillData;
//       rest = { ...rest, id, dateModified: new Date(), modifiedBy: this.state.currentUser.email };
//       setDoc(doc(fbdb, `${this.state.collection}/${id}`), rest, { merge: true });
//       this.closeModalById(e, modalId);
//     }
//   };

//   downloadCsvData(data, filename) {
//     const element = document.createElement("a");
//     const file = new Blob([data], { type: "text/csv" });
//     element.href = URL.createObjectURL(file);
//     element.download = filename;
//     document.body.appendChild(element);
//     element.click();
//   }

//   getNestedCsvField(key, elem) {
//     switch (key) {
//       case "company":
//         return elem[key] ? elem[key].label : "";
//       case "members":
//       case "fromRolechip":
//       case "toRolechip":
//       case "ccRolechip":
//         return elem[key] ? elem[key].map((e) => e.label).join(" - ") : "";
//       default:
//         return elem[key] ? elem[key] : "";
//     }
//   }

//   paginateTo = (currentPage) => {
//     this.setState((prevState) => this.updatePagination(prevState, currentPage));
//   };

//   updatePagination = (state, currentPage) => {
//     let sortedFilteredData = sortByStringField(state.filteredData, state.initialState.sortField);
//     sortedFilteredData = sortedFilteredData.map((e) => [e.id, e]);
//     return {
//       ...state,
//       pagination: {
//         ...state.pagination,
//         numberOfPages: Math.ceil(Object.keys(state.filteredData).length / state.pagination.itemsperPage),
//         currentPage,
//       },
//       paginatedData: Object.fromEntries(
//         sortedFilteredData.slice(state.pagination.itemsperPage * (currentPage - 1), state.pagination.itemsperPage * currentPage)
//       ),
//     };
//   };
// }

// export default CardsScreen;
