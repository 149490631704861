import { createSlice } from "@reduxjs/toolkit";

export const AppUISlice = createSlice({
  name: "AppUI",
  initialState: {
    isLoading: true,
    sideBarOpen: true,
    currentPath: [],
    service: "dashboard",
    appsMenu: {
      inspections: { link: "inspections", label: "Inspections", icon: "search" },
    },
    userMenu: {
      dashboard: { link: "dashboard", label: "Dashboard", icon: "dashboard" },
      directory: { link: "directory", label: "Project Directory", icon: "group" },
    },
    adminMenu: {
      projects: { link: "projects", label: "Projects List", icon: "insert_drive_file" },
      users: { link: "users", label: "Users Management", icon: "person" },
      companies: { link: "companies", label: "Companies", icon: "corporate_fare" },
    },
  },
  reducers: {
    addUserMenu(state, { payload }) {
      state.userMenu[payload] = state.appsMenu[payload];
    },
    removeUserMenu(state, { payload }) {
      delete state.userMenu[payload];
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setSideBarOpen(state, { payload }) {
      state.sideBarOpen = payload;
    },
    setService(state, { payload }) {
      const { link, isAdmin } = payload;
      let menus = [];
      if (isAdmin) menus = { ...state.userMenu, ...state.adminMenu };
      else menus = state.userMenu;

      // const filteredMenu = menus.filter((e) => e.link === link);

      if (menus[link]) state.service = menus[link].link;
      else state.service = "dashboard";
    },
  },
});

export const { addUserMenu, setIsLoading, setSideBarOpen, setCurrentPath, setService, removeUserMenu } = AppUISlice.actions;

export const sideBarOpenRS = (state) => state.appUI.sideBarOpen;
export const isLoadingRS = (state) => state.appUI.isLoading;
export const userMenuRS = (state) => state.appUI.userMenu;
export const adminMenuRS = (state) => state.appUI.adminMenu;
export const serviceRS = (state) => state.appUI.service;

export default AppUISlice.reducer;
