import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setupApp, useAppEffects } from "./components/utils/SetupApp";
import Router from "./services/router/Router";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    setupApp(dispatch);
    // eslint-disable-next-line
  }, []);

  useAppEffects();

  return <Router />;
}

export default App;
